const WIDGET = '.js-tabs';
const ACTIVECLASS = 'current';
const MOVEBUTTON = '.tabs__move';  // Nová konstanta pro tlačítko přesunu

class Tabs {
  constructor() {
    this.elements = document.querySelectorAll(WIDGET);

    this.elements.forEach((el) => {
      const nav = el.querySelectorAll('.js-tabs-nav > *');
      const select = el.querySelector('.js-tabs-select');
      const moveButton = el.querySelector(MOVEBUTTON); // Najít tlačítko přesunu

      nav.forEach((el) => {
        el.addEventListener('click', this.clickHandler, false);
      });

      if (select) {
        select.addEventListener('change', this.selectHandler, false);
      }

      if (moveButton) {
        moveButton.addEventListener('click', () => this.moveNext(el), false); // Přidání posluchače událostí pro tlačítko přesunu
      }
    });
  }

  clickHandler = (e) => {
    const button = e.currentTarget;
    const widget = button.closest(WIDGET);

    this.switchTab(widget, this.getIndex(button));
    button.classList.add(ACTIVECLASS);
  };

  selectHandler = (e) => {
    const select = e.currentTarget;
    const widget = select.closest(WIDGET);

    this.switchTab(widget, select.selectedIndex);
  };

  switchTab = (widget, index) => {
    const tabs = widget.querySelectorAll('.js-tabs-list > *');

    this.reset(widget);

    tabs.forEach((tab) => {
      if (this.getIndex(tab) === index) {
        tab.removeAttribute('hidden');
      }
    });

    window.dispatchEvent(new CustomEvent('scroll'));
  };

  getIndex = (el) => {
    return [...el.parentNode.children].indexOf(el);
  };

  reset = (widget) => {
    const nav = widget.querySelectorAll('.js-tabs-nav > *');
    const tabs = widget.querySelectorAll('.js-tabs-list > *');

    nav.forEach((button) => {
      button.classList.remove(ACTIVECLASS);
    });

    tabs.forEach((tab) => {
      tab.setAttribute('hidden', 'hidden');
    });
  };

  moveNext = (widget) => {
    const navItems = widget.querySelectorAll('.js-tabs-nav > *');
    let activeIndex = 0;

    navItems.forEach((item, index) => {
      if (item.classList.contains(ACTIVECLASS)) {
        activeIndex = index;
      }
    });

    const nextIndex = (activeIndex + 1) % navItems.length; // Cyklický posun na další tab
    navItems[nextIndex].click(); // Simulovat kliknutí na následující tab
  };
}

new Tabs();
