import Splide from '@splidejs/splide';

const EL = '.js-slider-strip';
const ACTIVE_CLASS = 'is-active';
const ELS = document.querySelectorAll(EL);

ELS.forEach((EL) => {
  new Splide( EL, {
    type: "loop",
    rewind: true,
    arrows: true,
    pagination: false,
    autoWidth: true,
    gap: '37px',
    breakpoints: {
      768: {
        height: '220px',
      },
    },
  }).mount();
});
