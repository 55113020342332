document.querySelectorAll('.js-megamenu').forEach(function(item) {
    item.addEventListener('click', function(event) {
        event.preventDefault();

        if (window.innerWidth <= 1024) {
            var nearestMenu = this.closest('.has-megamenu').querySelector('.megamenu');

            if (nearestMenu) {
                nearestMenu.classList.toggle('is-active');
                this.classList.toggle('is-active');
            }
        }
    });
});
