function updateRangeInput() {
  var rangeInput = document.getElementById("Range");

  if (!rangeInput) {
    return;
  }

  var directValue = rangeInput.value;
  var coefficient = 0.029;
  var multipliedValue = directValue * coefficient;
  var value = (rangeInput.value - rangeInput.min) / (rangeInput.max - rangeInput.min) * 100;

  document.getElementById("directValue").textContent = directValue;
  document.getElementById("multipliedValue").textContent = multipliedValue;

  rangeInput.style.setProperty('--filled-track-width', value + '%');
}

document.addEventListener('DOMContentLoaded', updateRangeInput);

if (document.getElementById("Range")) {
  document.getElementById("Range").addEventListener('input', updateRangeInput);
}
