import Splide from '@splidejs/splide';
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';

const EL = '.js-logos'
const ACTIVE_CLASS = 'is-active'
const ELS = document.querySelectorAll(EL);

ELS.forEach((EL) => {
  new Splide( EL, {
    type: "loop",
    rewind: true,
    arrows: false,
    pagination: false,
    fixedWidth: '120px',
    gap: '113px',
    breakpoints: {
      769: {
        gap: '60px',
      },
      480: {
        gap: '30px',
      },
    },
  } ).mount({AutoScroll});
});
